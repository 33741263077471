@font-face {
  font-family: 'Gotham Pro';
  src: url('/wp-content/themes/second/css/fonts/GothamPro-Bold.eot');
  src: local('Gotham Pro Bold'), local('GothamPro-Bold'),
  url('/wp-content/themes/second/css/fonts/GothamPro-Bold.eot?#iefix') format('embedded-opentype'),
  url('/wp-content/themes/second/css/fonts/GothamPro-Bold.woff') format('woff'),
  url('/wp-content/themes/second/css/fonts/GothamPro-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Gotham Pro';
  src: url('/wp-content/themes/second/css/fonts/GothamPro-Light.eot');
  src: local('Gotham Pro Light'), local('GothamPro-Light'),
  url('/wp-content/themes/second/css/fonts/GothamPro-Light.eot?#iefix') format('embedded-opentype'),
  url('/wp-content/themes/second/css/fonts/GothamPro-Light.woff') format('woff'),
  url('/wp-content/themes/second/css/fonts/GothamPro-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham Pro';
  src: url('/wp-content/themes/second/css/fonts/GothamPro-Black.eot');
  src: local('Gotham Pro Black'), local('GothamPro-Black'),
  url('/wp-content/themes/second/css/fonts/GothamPro-Black.eot?#iefix') format('embedded-opentype'),
  url('/wp-content/themes/second/css/fonts/GothamPro-Black.woff') format('woff'),
  url('/wp-content/themes/second/css/fonts/GothamPro-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'Gotham Pro';
  src: url('/wp-content/themes/second/css/fonts/GothamPro.eot');
  src: local('Gotham Pro'), local('GothamPro'),
  url('/wp-content/themes/second/css/fonts/GothamPro.eot?#iefix') format('embedded-opentype'),
  url('/wp-content/themes/second/css/fonts/GothamPro.woff') format('woff'),
  url('/wp-content/themes/second/css/fonts/GothamPro.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Gotham Pro';
  src: url('/wp-content/themes/second/css/fonts/GothamPro-Medium.eot');
  src: local('Gotham Pro Medium'), local('GothamPro-Medium'),
  url('/wp-content/themes/second/css/fonts/GothamPro-Medium.eot?#iefix') format('embedded-opentype'),
  url('/wp-content/themes/second/css/fonts/GothamPro-Medium.woff') format('woff'),
  url('/wp-content/themes/second/css/fonts/GothamPro-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}