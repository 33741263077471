@import 'fonts';
@import 'slick';
@import 'slick-theme';
@import 'bootstrap';

$emphasis: #4FD29E;
$semphasis: #333745;

body {
  font-family: 'Open Sans';
  padding: 0;
  margin: 0;
  color: $semphasis;
}

a {
  text-decoration: none;
}

.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box;
}

@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}

@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}

section {
  padding-top: 100px;
  padding-bottom: 100px;
}

.clearfix:after {
  display: table;
  content: " ";
  clear: both;
}

.row {
  //display: flex;
  //justify-content: space-around;
  padding-bottom: 15px;
}

.col-md-6, .col-sm-6,
.col-sm-12, .col-md-12 {
  img {
    width: 100%;
    object-fit: cover;
  }
}

header {
  .container {
    display: flex;
    justify-content: space-between;
    height: 100%;
    align-items: center;
  }
  .top-line {
    font-size: 14px;
    background-color: #4FD29E;
    color: #333745;
    height: 64px;
    line-height: 18px;
    vertical-align: middle;
    .phone {
      font-weight: bold;
    }
    .logo-body {
      width: 295px;
      img {
        display: none;
      }
    }
    a {
      color: #333745;
      text-decoration: none;
    }

  }
  > .menu {
    height: 64px;
    background-color: rgba(51, 55, 69, 0.9);
    .logo {
      width: 200px;
      position: relative;
      z-index: 1;
    }

    nav {
      ul {
        padding-left: 0;
        display: flex;
        transition-duration: 0.4s;
        position: relative;
        list-style: none;
        li {
          //padding: 0 20px;
        }
        a {
          display: inline-block;
          line-height: 64px;
          font-family: "Gotham Pro";
          font-weight: 300;
          color: #FFFFFF;
          padding: 0 12px;
          position: relative;
          z-index: 1;
          height: 100%;

        }
        a:after {
          position: absolute;
          content: '';
          bottom: 0;
          height: 0;
          left: 1px;
          width: calc(100% - 2px);

          background-color: #5c606f;
          transition-duration: .2s;
          z-index: -10;
        }
        a:hover:after {
          height: 100%;
        }
        /*a:first-child {
          margin-left: -20px;
        }
        a:last-child {
          margin-right: -20px;
        }*/
      }
    }
    nav.open {
      right: 0;

    }

  }
}
.downloading{
  img{
    max-width: 100px;
  }
}
.main-slider {
  padding-top: 0;
  padding-bottom: 0;
  position: relative;
  .slider-item {
    position: relative;
    max-height: 100vh;
    color: white;
    img {
      object-fit: cover;
      max-width: 100%;
      min-height: 100%;
    }
    .slider__text-block {
      position: absolute;
      top: 10%;
      width: 100%;
      text-align: center;
    }
  }
  .slick-dots {
    bottom: 15px;
  }
}

.slick-dotted.slick-slider {
  margin-bottom: 0;
}

.slider-title {
  font-size: 72px;
  font-weight: 900;
  line-height: 90px;
  color: white;
  text-transform: uppercase;
  span {
    color: $emphasis;
  }
}

.text-under-title {
  font-size: 28px;
  margin-top: 20px;
  margin-bottom: 80px;
}

.slider-desc {
  font-size: 32px;
  font-family: "Gotham Pro";
  font-weight: 300;
  margin-bottom: 15px;
}

.slider-emphasis {
  font-size: 48px;
  font-family: "Gotham Pro";
  font-weight: 900;
  color: $emphasis;
}

.slider-btn {
  border: 3px solid white;
  text-transform: uppercase;
  font-size: 18px;
  text-align: center;
  font-weight: bold;
  line-height: 62px;
  width: 270px;
  margin: 0 auto;
  margin-top: 90px;
  cursor: pointer;
  transition-duration: .2s;
  position: relative;
  z-index: 1;
  letter-spacing: 2px;
}




.slider-btn:hover {
  border-color: white;
  color: $semphasis;
}

.slider-btn:after {
  position: absolute;
  content: '';
  bottom: 0;
  left: 0;
  height: 0;
  width: 100%;
  background-color: #fff;
  transition-duration: .2s;
  z-index: -1;
}

.slider-btn:hover:after {
  height: 100%;
}

.btn-next-screen {
  cursor: pointer;
  position: absolute;
  bottom: 50px;
  width: 40px;
  left: 50%;
  transform: translateX(-50%);
  img {
    width: 100%;
  }
}

.benefits {
  padding-top: 70px;
  padding-bottom: 90px;
  background-image: url('/wp-content/themes/second/image/template/backgrounds/bg-b.jpg');
  background-position: center top;
  background-size: cover;
  .container {
    display: flex;
    justify-content: space-between;
  }
  .ben-item {
    text-align: center;
    width: 200px;
    .ben-title {
      text-transform: uppercase;
      color: $semphasis;
      font-size: 22px;
      font-family: "Gotham Pro";
      font-weight: bold;
      line-height: 32px;
      position: relative;
      padding-bottom: 0px;
      min-height: 84px;
      width: 90%;
      margin: 0 auto;
      margin-top: 40px;
      margin-bottom: 20px;
    }
    .ben-title:after {
      position: absolute;
      content: '';
      bottom: 0px;
      height: 1px;
      width: 160px;
      background-color: $emphasis;
      left: 50%;
      transform: translateX(-50%);
    }
    .ben-desc {
      font-size: 14px;
      color: #434343;
      line-height: 18px;
    }
  }
}

.about {
  background-image: url("/wp-content/themes/second/image/template/backgrounds/bg-about.jpg");
  background-size: cover;
  color: white;
  .container {
    display: flex;
    justify-content: space-around;
  }
  .about-logo {
    width: 30%;
    margin-left: -100px;
    img {
      width: 470px;
    }
  }
  .about-text {
    width: 70%;
    .title {
      text-transform: uppercase;
      font-weight: 900;
      font-size: 42px;
    }
    p {
      padding-top: 20px;
      line-height: 24px;
      font-size: 14px;
    }
    a {
      color: $emphasis;
      font-size: 18px;
      text-transform: uppercase;
      letter-spacing: 3px;
      font-family: "Gotham Pro";
      font-weight: bold;
      display: block;
      margin-top: 60px;
      transition-duration: 0.2s;
      &:hover {
        color: white;
      }
    }
  }
}

.title-vs-bg {
  font-size: 42px;
  text-align: center;
  width: 100%;
  color: $semphasis;
  font-family: "Gotham Pro";
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 80px;
  background-image: url("/wp-content/themes/second/image/template/svg/small stripes.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100px;
  line-height: 70px;
}

.catalog {
  background-image: url(/wp-content/themes/second/image/template/backgrounds/bg-cat.png);
  background-size: 800px;
  background-position: center top;
  background-repeat: no-repeat;
}

.cat-container {
  .cat-item {
    background-color: #fff;
    padding: 55px;
    margin-left: 35px;
    margin-right: 35px;
    text-align: center;
    border: 1px solid #E4E4E4;
    img.wp-post-image {
      border-radius: 50%;
      width: 215px;
      height: 215px;
      object-fit: cover;
      margin: 0 auto;
    }
  }
  .cat-title {
    padding: 30px 0;
    font-size: 18px;
    font-family: "Gotham Pro";
    font-weight: bold;

  }
  .cat-desc {
    font-size: 14px;
    line-height: 18px;
    min-height: 165px;
  }
  .cat-btn {
    margin-top: 30px;
    display: block;
    color: $semphasis;
    font-size: 18px;
    font-family: "Gotham Pro";
    font-weight: bold;
    letter-spacing: 2px;
    border: 3px solid $semphasis;
    line-height: 64px;
    width: 100%;
    box-sizing: border-box;
    text-align: center;
    transition-duration: 0.2s;
    text-transform: uppercase;
    position: relative;
    z-index: 1;
  }
  .cat-btn:hover {
    color: #fff;
  }
}

section.counter {
  background-image: url('/wp-content/themes/second/image/template/backgrounds/count-bg.jpg');
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;
  color: white;
  .title-vs-bg {
    color: white;
    line-height: 140px;
    background-size: 140px;
    background-image: url('/wp-content/themes/second/image/template/svg/small-stripes-color.svg');
    span {
      color: $emphasis;
    }
  }

}

.count-container {
  display: flex;
  justify-content: space-between;
}

.count-item {
  text-align: center;

  width: 160px;
}

.count-title {
  font-size: 22px;
  font-family: "Gotham Pro";
  font-weight: bold;
  line-height: 32px;
  letter-spacing: 1.5px;
}

.round-cont {
  position: relative;

}

.circle-num {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 42px;
  font-family: "Gotham Pro";
  font-weight: 300;

}

.circles {
  fill: none;
  stroke-width: 15;
}

.circle__second {
  stroke: $emphasis;
  stroke-dasharray: .0010 282.6;
  transition: all 1s;
}

.a, .c {
  fill: none;
}

.circle__first {
  stroke: $emphasis;
  stroke-width: 15px;
  stroke-dasharray: 1 5;
  opacity: 0.5;
}

.b {
  stroke: none;
}

.circles--active {
  .circle-0 {
    stroke-dasharray: .001 282.6;
  }
  .circle-10 {
    stroke-dasharray: 28.26 282.6;
  }
  .circle-15 {
    stroke-dasharray: 42.39 282.6;
  }
  .circle-20 {
    stroke-dasharray: 56.52 282.6;
  }
  .circle-25 {
    stroke-dasharray: 70.65 282.6;
  }
  .circle-30 {
    stroke-dasharray: 84.78 282.6;
  }
  .circle-35 {
    stroke-dasharray: 98.91 282.6;
  }
  .circle-40 {
    stroke-dasharray: 113.04 282.6;
  }
  .circle-45 {
    stroke-dasharray: 127.17 282.6;
  }
  .circle-50 {
    stroke-dasharray: 141.3 282.6;
  }
  .circle-55 {
    stroke-dasharray: 155.43 282.6;
  }
  .circle-60 {
    stroke-dasharray: 169.56 282.6;
  }
  .circle-65 {
    stroke-dasharray: 183.69 282.6;
  }
  .circle-70 {
    stroke-dasharray: 197.82 282.6;
  }
  .circle-75 {
    stroke-dasharray: 211.95 282.6;
  }
  .circle-80 {
    stroke-dasharray: 226.08 282.6;
  }
  .circle-85 {
    stroke-dasharray: 240.21 282.6;
  }
  .circle-90 {
    stroke-dasharray: 254.34 282.6;
  }
  .circle-95 {
    stroke-dasharray: 268.47 282.6;
  }
  .circle-100 {
    stroke-dasharray: 282.6 282.6;
  }
}

.how {
  background-image: url("/wp-content/themes/second/image/template/backgrounds/bg-how.png"), url("/wp-content/themes/second/image/template/backgrounds/girl.png");
  background-position: center top, -240px top;
  background-repeat: no-repeat;
  background-size: cover, 700px;

  .how-container {
    display: flex;
    justify-content: space-between;
  }
  .how-item {
    text-align: center;
    position: relative;
    img {
      width: 100px;
      margin-bottom: 25px;
    }
    &:after {
      position: absolute;
      content: '';
      transform: translateX(200px);
      width: 270px;
      z-index: -2;
      right: 0;
      background-image: url('/wp-content/themes/second/image/template/svg/line.svg');
      height: 3px;

      //border-bottom: 1px dotted #4FD29E;
    }

  }
  .how-item:last-child:after {
    background-image: none;
    width: 0;
  }

  .h-line1:after {
    top: 40px;
  }
  .h-line2:after {
    right: 0;
    top: 90px;
  }
  .h-line3:after {
    top: 40px;
    right: -10px;
  }
  .how-title {
    font-size: 18px;
    font-family: "Gotham Pro";
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    width: 180px;
    line-height: 24px;
  }
}

.call-form {
  .form-title {
    text-align: center;
    font-size: 28px;
    font-family: "Gotham Pro";
    font-weight: bold;
    line-height: 32px;
    height: 160px;
    padding-top: 150px;
    padding-bottom: 40px;
    max-width: 800px;
    margin: 0 auto;
    background-image: url('/wp-content/themes/second/image/template/svg/headset.svg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 140px;
  }
  .form-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .input-c {
    margin-right: 40px;
    position: relative;
    svg {
      position: absolute;
      left: 20px;
      font-size: 18px;
      color: #979797;
      top: 50%;
      transform: translateY(-50%);
    }
    input {
      border: 1px solid $semphasis;
      padding-left: 50px;
      height: 66px;
      font-size: 16px;
      line-height: 64px;
      max-width: 240px;
      box-sizing: border-box;
      background-color: #ffffff;
    }
    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: #979797;
      font-size: 16px;
    }
    ::-moz-placeholder { /* Firefox 19+ */
      color: #979797;
      font-size: 16px;
    }
    :-ms-input-placeholder { /* IE 10+ */
      color: #979797;
      font-size: 16px;
    }
    :-moz-placeholder { /* Firefox 18- */
      color: #979797;
      font-size: 16px;
    }
  }
  .form-btn {
    color: $semphasis;
    background-color: $emphasis;
    width: 240px;
    line-height: 68px;
    font-family: "Gotham Pro";
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    text-align: center;
    cursor: pointer;
    transition-duration: 0.2s;
    border: 1px solid $emphasis;
  }
  .form-btn:hover {

    background-color: transparent;
  }
}

.samtovar {
  padding-bottom: 70px;
}

#up {
  width: 100px;
  height: 100px;
  position: fixed;
  bottom: 20px;
  right: 10px;
  background-image: url('/wp-content/themes/second/image/template/up_arrow.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  opacity: 0.7;
  cursor: pointer;
  display: none;
  transition-duration: .2s;
}

#up:hover {
  opacity: 1;
}

.partners {
  .slick-initialized .slick-slide {
    display: flex;
    align-items: center;
  }
  .partner-item {
    height: 100px;
    img {
      width: 200px;
      margin: 0 auto;

    }
  }
}

footer {
  padding-top: 40px;
  background-color: $semphasis;
  .logo-line {
    padding-left: 10%;
    padding-right: 10%;
    display: flex;
    align-items: center;
  }
  .logo {
    width: 400px;
    margin: 0 auto;
    text-align: center;
    img {
      width: 250px;
      margin: 0 auto;
    }
  }
  .phone {
    a:last-child {
      //display: block;
      margin-left: 10px;
    }
  }
  .address {
    text-align: center;
  }
  .email {
    text-align: right;
  }
  .footer-hr {
    width: calc((100% - 400px) / 2);
    height: 1px;
    background-color: $emphasis;
  }
  a {
    color: white;
  }
  .contacts-line {
    padding-top: 40px;
    padding-bottom: 40px;
    display: flex;
    justify-content: space-between;
    color: white;
    font-size: 18px;
    > div {
      width: 33%;
    }

  }

  .soc-line {
    font-size: 30px;
    text-align: center;
    padding-bottom: 40px;
    a {
      margin-right: 60px;
    }
    a:last-child {
      margin-right: 0;
    }
  }
  .copy {
    font-size: 14px;
    color: #54596E;
    text-align: center;
    padding-bottom: 40px;
    a {
      color: #54596E;
    }
  }
}

.mob-btn {
  display: none;
  cursor: pointer;
  margin-right: 15px;
  .line {
    height: 1px;
    width: 30px;
    background-color: #fff;
    margin-bottom: 6px;
  }
}

.cross {
  display: none;
}

.bg {
  background-color: rgba(0, 0, 0, 0.90);
  pointer-events: none;
  opacity: 0;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 15;
  transition-duration: 0.2s;
}

.bg.open {
  opacity: 1;
  pointer-events: all;
}

.msg-form {
  text-align: center;
  padding-top: 40px;

}

.second-page {
  padding-top: 100px;
}

.title-second-page {

}

.news-item {
  display: flex;
  padding-bottom: 70px;
}

.news {
  &__img {
    width: 200px;
    height: 200px;
    padding-right: 30px;
    overflow: hidden;
    box-sizing: border-box;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  &__text-block {
    width: 80%;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
  }

  &__title {
    color: $semphasis;
    font-size: 24px;
    margin-bottom: auto;
    h3 {
      color: $semphasis;
      font-size: 24px;
      margin-top: 0;
    }
  }
  &__link {
    margin-top: 40px;
    color: $semphasis;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 2px;
    display: block;
  }
}

.breadcrumbs {
  padding-top: 10px;
  padding-bottom: 20px;
  a {
    font-size: 18px;
    text-decoration: underline;
    color: $semphasis;
  }
}

.delivery {
  img {
    width: 100%;
  }
}

.contact {
  span {
    display: inline-block;
    margin-bottom: 10px;
    svg {
      padding-right: 20px;
      color: $emphasis;
    }
    a {
      color: $semphasis;
      padding-left: 0;

    }
  }
}

.cat-grid {
  display: flex;
  flex-wrap: wrap;

  .cat-item {
    width: 30%;
    margin-right: 5%;
    text-align: center;
    border: 1px solid #E4E4E4;
    background-color: #fff;
    padding: 10px 20px;
    box-sizing: border-box;
    margin-bottom: 30px;
    min-height: 460px;
    color: $semphasis;
    .cat__desc {
      min-height: 130px;
    }
    img {
      border-radius: 50%;
      width: 200px;
      height: 200px;
      object-fit: cover;
    }
    .cat__title {
      padding: 30px 0;
      font-size: 18px;
      font-family: "Gotham Pro";
      font-weight: bold;
      color: $semphasis;
    }
    .cat-btn {
      margin-top: 30px;
      display: block;
      color: #333745;
      font-size: 18px;
      font-family: "Gotham Pro";
      font-weight: bold;
      letter-spacing: 2px;
      border: 3px solid #333745;
      line-height: 64px;
      width: 100%;
      box-sizing: border-box;
      text-align: center;
      transition-duration: 0.2s;
      text-transform: uppercase;
      position: relative;
      z-index: 1;
    }

    .cat__text-block {
      padding-top: 15px;
    }
  }
  .cat-item:nth-child(3n) {
    margin-right: 0;
  }

}

.cat-grid .cat-item .cat-btn:hover {
  color: white;
}

.cat-btn:after {
  position: absolute;
  content: '';
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;
  background-color: $semphasis;
  transition-duration: 0.2s;
  z-index: -1;
}

.cat-btn:hover:after {
  height: 100%;
}

.price-title {
  position: relative;
  text-transform: uppercase;
  padding-bottom: 25px;
  margin-bottom: 25px;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 1px;
  color: $semphasis;
  svg {
    color: $emphasis;
  }
}

.price-title:after {
  position: absolute;
  content: '';
  width: 160px;
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: $emphasis;
}

.price-table {
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
  td {
    width: 25%;
    padding: 15px;
    text-align: center;
    border: 1px solid $emphasis;
  }
  tr:nth-child(2) {
    color: white;
    font-weight: bold;
    td:nth-child(even) {
      background-color: $emphasis;
    }
    td:nth-child(odd) {
      background-color: #25c585;
    }
  }
}

.samtovar{
  .call-form .form-title{
    padding-top: 60px;
    background-position: center 20px;
  }
}
@media screen and (max-width: 1300px) {
  .second-page {
    margin-top: 60px;
  }
  .mob-btn {
    display: block;
  }
  header {
    .top-line {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 2;
      right: 0;
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.20);
      .logo-body {
        height: 90%;
        margin-right: auto;
        img {
          display: block;
          height: 100%;
        }
      }
    }
    .menu {
      .logo {
        display: none;
      }
    }
    .address,
    .email,
    .phone {
      margin-right: 40px;
      cursor: pointer;
      position: relative;
      svg {
        font-size: 24px;
      }
      span {
        background-color: $semphasis;
        color: #fff;
        position: absolute;
        bottom: -100px;
        transition-duration: 0.2s;
      }
    }
    .address:hover span,
    .email:hover span,
    .phone:hover span {
      display: block;
      bottom: -20px;
      left: -1px;
      transform: translate(-50%, 100%);
      text-align: center;
    }
  }
  .main-slider {
    margin-top: 60px;
  }
  .cross {
    position: absolute;
    top: 40px;
    right: 20px;
    font-size: 20px;
    color: white;
    z-index: 20;
    display: block;
    cursor: pointer;
  }
  header {
    .menu {
      height: 0;
      nav {

        padding-top: 50px;
        height: 100%;
        background-color: $semphasis;
        position: fixed;
        width: 260px;
        right: -100%;

        top: 0;
        z-index: 20;
        transition-duration: 0.4s;
        ul {
          display: flex;
          flex-direction: column;
          a:first-child {
            margin-left: 0;
          }
          a:last-child {
            margin-right: 0;
          }
          a {
            font-size: 24px;
            font-weight: 600;
            line-height: 44px;
          }
        }
      }
    }
    .top-line {
      .phone {
        span {
          color: $semphasis;
        }
      }
      span {
        position: absolute;
        display: none;
        z-index: 10;
        padding: 10px 20px;
        min-width: 160px;
        a {
          color: white;
          display: block;
        }
        &:after {
          position: absolute;
          content: '';
          border: 10px solid transparent;
          border-bottom: 10px solid $semphasis;
          top: -20px;
          left: 50%;
        }
      }
    }
  }
  .main-slider .slider-item {
    height: 80vh;
  }
  .slider-title {
    font-size: 54px;
    line-height: 58px;
  }
  .text-under-title {
    font-size: 22px;
    margin-bottom: 50px;
  }
  .slider-desc {
    font-size: 26px;
  }
}

@media screen and (max-width: 1200px) {
  .how {
    background-size: cover, 0;
  }
}

@media screen and (max-width: 992px) {
  .cat-grid .cat-item {
    width: 47%;
  }
  .cat-grid .cat-item:nth-child(3n) {
    margin-right: 5%;
  }
  .cat-grid .cat-item:nth-child(2n) {
    margin-right: 0;
  }

  .benefits {
    .container {
      flex-wrap: wrap;
    }
    .ben-item {
      img {
        width: 200px;
      }
      margin-bottom: 40px;
      width: 45%;
    }
  }
  .about .about-logo {
    opacity: 0.15;
    position: absolute;
  }
  .cat-container .cat-item {
    margin-left: 15px;
    margin-right: 15px;
  }
  .how {
    background-size: cover, 0;
    .how-container {
      flex-wrap: wrap;
      justify-content: center;
      .how-item {
        width: 40%;
        margin-bottom: 20px;
        text-align: center;
        &:after {
          display: none;
        }
      }
    }
    .how-title {
      width: auto;
    }
  }
  .main-slider .slider-item .slider__text-block {
    top: 5%;
  }
  .count-container {
    flex-wrap: wrap;
  }
  .count-item {
    width: 49%;
    margin-bottom: 40px;
  }
  .round-cont {
    width: 150px;
    margin: 0 auto;
  }
  .count-title {
    margin-top: 10px;
  }
  section.counter .title-vs-bg {
    height: 140px;
    line-height: 48px;
  }
  .call-form {
    .form-container {
      flex-wrap: wrap;
      flex-direction: column;
    }
    .form-title {
      padding-bottom: 10px;
    }
    .input-c {
      margin-right: 0;
      margin-bottom: 40px;
    }
  }
  footer .contacts-line {
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    > div {
      margin-bottom: 15px;
      width: 100%;
    }
  }
  footer .email {
    text-align: center;
  }
}

@media (max-width: 770px) {
  .news-item {
    flex-wrap: wrap;
  }
  .news__img {
    width: 100%;
    padding-right: 0;
  }
  .news__text-block {
    width: 100%;
  }
  .cat-grid {
    justify-content: center;
  }
  .cat-grid .cat-item {
    width: 100%;
    margin-right: 0;
  }
  .cat-grid .cat-item:nth-child(3n) {
    margin-right: 0;
  }
  .cat-grid .cat-item:nth-child(2n) {
    margin-right: 0;
  }
  .second-page {
    padding-top: 40px;
  }
  .btn-next-screen {
    display: none;
  }
  .about .about-logo {
    width: 100%;
    margin-left: 0;
    overflow: hidden;
  }
  .about .about-logo img {
    width: 300px;
    margin: 0 auto;
  }
  .about .about-text {
    width: 100%;
  }
  .call-form .input-c input {
    max-width: 300px;
  }
  .call-form .form-title {
    height: 220px;
  }
}

@media screen and (max-width: 680px) {

  header .address,
  header .email,
  header .phone {
    margin-right: 15px;
  }
  .slider-btn {
    margin-top: 20px;
  }
  .main-slider .slider-item {
    height: calc(100vh - 60px);
  }
  .slider-title {
    font-size: 44px;
    line-height: 50px;
  }
  .title-vs-bg {
    line-height: 50px;
  }
  .benefits {
    .container {
      justify-content: center;
    }
    .ben-item {
      width: 100%;
      .ben-desc {
        max-width: 320px;
        margin: 0 auto;
      }
    }
  }
  .count-container {
    flex-direction: column;
    justify-content: center;
  }
  .count-item {
    width: 100%;
  }
  .cat-container .cat-item {
    padding: 15px;
  }
  .how .how-container {
    flex-direction: column;
    .how-item {
      width: 100%;
    }
  }
}

@media (max-width: 390px) {
  .cat-container .cat-title {
    min-height: 102px;
  }
}

::-webkit-scrollbar-thumb {
  background-color: $emphasis;
}
